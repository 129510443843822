import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getPostsReport = (params, thunkAPI) => {
  return axiosClient.get("v1/post-report", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getPostsReportDetail = (id, thunkAPI) => {
  return axiosClient.get("v1/post-report/" + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const acceptPostsReportStatus = (id, data) => {
  return axiosClient.patch(`v1/post-report/${id}/accept-report-post`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const rejectPostsReportStatus = (id) => {
  return axiosClient.patch(
    `v1/post-report/${id}/reject-report-post`,
    {},
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const exportPostReport = (params, thunkAPI) => {
  return axiosClient.get("/v1/export/post-reports", {
    signal: thunkAPI?.signal,
    params,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getPostsReport,
  getPostsReportDetail,
  acceptPostsReportStatus,
  rejectPostsReportStatus,
  exportPostReport,
};
