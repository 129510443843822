import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getCommentReport, getCommentReportDetail } from "./commentReportAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getCommentReportsAsync = createAsyncThunk(
  "commentReports",
  async (params, thunkAPI) => {
    try {
      const response = await getCommentReport(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCommentReportDetailAsync = createAsyncThunk(
  "commentReportDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getCommentReportDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const commentReportSlice = createSlice({
  name: "commentReport",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearCommentReportDetail: (state) => {
      state.detail = undefined;
    },
    clearCommentReports: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getCommentReportsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCommentReportsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCommentReportsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getCommentReports",
        };
      })
      .addCase(getCommentReportDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCommentReportDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCommentReportDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "commentReportDetail",
        };
      });
  },
});

export const selectCommentReport = (state) => state.commentReport.list;
export const selectCommentReportDetail = (state) => state.commentReport.detail;
export const commentReportStatus = (state) => state.commentReport.status;
export const commentReportsError = (state) => state.commentReport.error;

export const { clearCommentReportDetail, clearCommentReports, setIdleStatus } =
  commentReportSlice.actions;

export default commentReportSlice.reducer;
