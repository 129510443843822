import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addKeyword, getKeyword, updateKeyword } from "./keywordAPI";
import { message } from "antd";
import _ from "lodash";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getKeywordAsync = createAsyncThunk(
  "keyword",
  async (params, thunkAPI) => {
    try {
      const response = await getKeyword(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateKeywordAsync = createAsyncThunk(
  "update-keyword",
  async (params, thunkAPI) => {
    try {
      const response = await updateKeyword(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addKeywordAsync = createAsyncThunk(
  "add-keyword",
  async (params, thunkAPI) => {
    try {
      const response = await addKeyword(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const keywordSlice = createSlice({
  name: "keyword",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearKeyword: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getKeywordAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getKeywordAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getKeywordAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getKeyword",
        };
      })
      .addCase(updateKeywordAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateKeywordAsync.fulfilled, (state, action) => {
        let list = _.cloneDeep(state.list);
        state.detail = action.payload?.data;
        let index = _.findIndex(list?.data, ["id", action.payload?.data?.id]);
        if (index !== -1) {
          list.data[index] = action?.payload?.data;
        }
        state.list = list;
        state.status = "idle";
        state.error = undefined;
        message.success("Cập nhật dữ liệu thành công");
      })
      .addCase(updateKeywordAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Cập nhật dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "update-keyword",
          };
        }
      })
      .addCase(addKeywordAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addKeywordAsync.fulfilled, (state, action) => {
        let clone = _.cloneDeep(state.list);
        clone.data = [action.payload.data, ...clone.data];

        state.list = clone;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(addKeywordAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "addKeyword",
        };
      });
  },
});

export const selectKeyword = (state) => state.keyword.list;
export const selectKeywordDetail = (state) => state.keyword.detail;
export const keywordStatus = (state) => state.keyword.status;
export const keywordsError = (state) => state.keyword.error;

export const { clearkeywords, setIdleStatus } = keywordSlice.actions;

export default keywordSlice.reducer;
