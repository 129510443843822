import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth/authSlice";
import memberReducer from "../features/member/memberSlice";
import userReducer from "../features/user/userSlice";
import postsReducer from "../features/posts/postsSlice";
import eventReducer from "../features/event/eventSlice";
import stickerReducer from "../features/sticker/stickerSlice";
import postsReportReducer from "../features/postsReport/postsReportSlice";
import memberReportReducer from "../features/memberReport/memberReportSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import notiReducer from "../features/noti/notiSlice";
import keywordReducer from "../features/keyword/keywordSlice";
import commentReportReducer from "../features/commentReport/commentReportSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    member: memberReducer,
    user: userReducer,
    posts: postsReducer,
    event: eventReducer,
    stickers: stickerReducer,
    postsReport: postsReportReducer,
    memberReport: memberReportReducer,
    noti: notiReducer,
    dashboard: dashboardReducer,
    keyword: keywordReducer,
    commentReport: commentReportReducer,
  },
});
