import {
  createAsyncThunk,
  // createAsytopicThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { message } from "antd";
import _ from "lodash";
import {
  getGroupStickers,
  getStickers,
  putGroupSticker,
  putSticker,
} from "./stickerAPI";

const initialState = {
  groupList: undefined,
  stickerList: undefined,
  detail: undefined,
  groupDetail: undefined,
  status: "idle",
  error: undefined,
};

export const getGroupStickersAsync = createAsyncThunk(
  "stickersGroup",
  async (params, thunkAPI) => {
    try {
      const response = await getGroupStickers(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getStickersAsync = createAsyncThunk(
  "stickers",
  async (params, thunkAPI) => {
    try {
      const response = await getStickers(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putStickerAsync = createAsyncThunk(
  "putSticker",
  async (data, thunkAPI) => {
    try {
      const response = await putSticker(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putGroupStickerAsync = createAsyncThunk(
  "putGroup",
  async (data, thunkAPI) => {
    try {
      const response = await putGroupSticker(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const stickerSlice = createSlice({
  name: "sticker",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    updateGroupStickersList: (state, action) => {
      let clone = _.cloneDeep(state.groupList);
      let data = clone?.data;
      data[action?.payload?.index].status = action?.payload?.status;
      state.groupList = clone;
    },
    updateStickersList: (state, action) => {
      let clone = _.cloneDeep(state.stickerList);
      let data = clone?.data;
      data[action?.payload?.index].status = action?.payload?.status;
      state.stickerList = clone;
    },
    clearStickers: (state) => {
      state.stickerList = undefined;
    },
    clearGroupStickers: (state) => {
      state.groupList = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getGroupStickersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGroupStickersAsync.fulfilled, (state, action) => {
        state.groupList = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getGroupStickersAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "stickersGroup",
        };
      })
      .addCase(getStickersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStickersAsync.fulfilled, (state, action) => {
        state.stickerList = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getStickersAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "stickers",
        };
      })
      .addCase(putStickerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putStickerAsync.fulfilled, (state, action) => {
        let clone = _.cloneDeep(state.stickerList);
        let index = _.findIndex(clone?.data, ["id", action?.payload?.data?.id]);
        if (index !== -1) {
          clone.data[index] = action.payload.data;
          state.stickerList = clone;
        }
        state.status = "done";
        state.error = undefined;
        message.success("Cập nhật dữ liệu thành công");
      })
      .addCase(putStickerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Cập nhật thất bại");
          state.error = {
            payload: action.payload,
            title: "putSticker",
          };
        }
      })
      .addCase(putGroupStickerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putGroupStickerAsync.fulfilled, (state, action) => {
        let clone = _.cloneDeep(state.groupList);
        let index = _.findIndex(clone?.data, ["id", action?.payload?.data?.id]);
        if (index !== -1) {
          clone.data[index] = action.payload.data;
          state.groupList = clone;
        }
        state.status = "done";
        state.error = undefined;
        message.success("Cập nhật dữ liệu thành công");
      })
      .addCase(putGroupStickerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Cập nhật thất bại");
          state.error = {
            payload: action.payload,
            title: "putGroup",
          };
        }
      });
  },
});

export const selectStickers = (state) => state.stickers.stickerList;
export const selectGroupStickers = (state) => state.stickers.groupList;
export const stickersStatus = (state) => state.stickers.status;
export const stickersError = (state) => state.stickers.error;

export const {
  clearStickerDetail,
  clearStickers,
  setIdleStatus,
  updateStickerDetail,
  updateGroupStickersList,
  updateStickersList,
} = stickerSlice.actions;

export default stickerSlice.reducer;
