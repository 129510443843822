import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getCommentReport = (params, thunkAPI) => {
  return axiosClient.get("v1/post-comment-report", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getCommentReportDetail = (id, thunkAPI) => {
  return axiosClient.get("v1/post-comment-report/" + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const acceptCommentReportStatus = (id, data) => {
  return axiosClient.patch(`v1/post-comment-report/${id}/accept-report`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const rejectCommentReportStatus = (id) => {
  return axiosClient.patch(
    `v1/post-comment-report/${id}/reject-report`,
    {},
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const exportCommentReport = (params, thunkAPI) => {
  return axiosClient.get("/v1/export/post-comment-report", {
    signal: thunkAPI?.signal,
    params,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getCommentReport,
  getCommentReportDetail,
  acceptCommentReportStatus,
  rejectCommentReportStatus,
  exportCommentReport,
};
