import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getEvent = (params, thunkAPI) => {
  return axiosClient.get("v1/events", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getEventDetail = (id, thunkAPI) => {
  return axiosClient.get("v1/events/" + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putEventStatus = (data) => {
  return axiosClient.patch(
    "v1/post-report",
    { ...data?.values, id: data?.id },
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const exportEvent = (params, thunkAPI) => {
  return axiosClient.get("/v1/export/events", {
    signal: thunkAPI?.signal,
    params,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getEvent, getEventDetail, putEventStatus, exportEvent };
