import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getGroupStickers = (params, thunkAPI) => {
  return axiosClient.get("v1/sticker-group", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getStickers = (params, thunkAPI) => {
  return axiosClient.get("v1/sticker", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postSticker = (data) => {
  return axiosClient.post("v1/sticker", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postGroupSticker = (data) => {
  return axiosClient.post("v1/sticker-group", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putSticker = ({ id, data }) => {
  return axiosClient.patch("v1/sticker/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putGroupSticker = ({ id, data }) => {
  return axiosClient.patch("v1/sticker-group/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const deleteSticker = (id) => {
  return axiosClient.delete("sticker/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportSticker = (params, options, thunkAPI) => {
  return axiosClient.get("sticker/export", {
    signal: thunkAPI?.signal,
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getGroupStickers,
  getStickers,
  postSticker,
  putSticker,
  putGroupSticker,
  deleteSticker,
  exportSticker,
  postGroupSticker,
};
