import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getMyInfo,
  getUserDetail,
  getUsers,
  postUser,
  putUserStatus,
  resetPasswordUser,
  putUser,
} from "./userAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getUsersAsync = createAsyncThunk(
  "users",
  async (params, thunkAPI) => {
    try {
      const response = await getUsers(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUserDetailAsync = createAsyncThunk(
  "userDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getUserDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getMyInfoAsync = createAsyncThunk(
  "myInfo",
  async (params, thunkAPI) => {
    try {
      const response = await getMyInfo(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postUserAsync = createAsyncThunk(
  "userPost",
  async (params, thunkAPI) => {
    try {
      const response = await postUser(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putUserAsync = createAsyncThunk(
  "userPut",
  async (params, thunkAPI) => {
    try {
      const response = await putUser(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusUserAsync = createAsyncThunk(
  "userStatus",
  async (params, thunkAPI) => {
    try {
      const response = await putUserStatus(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetPasswordUserAsync = createAsyncThunk(
  "userResetPassword",
  async (params, thunkAPI) => {
    try {
      const response = await resetPasswordUser(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearUserDetail: (state) => {
      state.detail = undefined;
    },
    clearUsers: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getUsersAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getUsers",
        };
      })
      .addCase(getUserDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getUserDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "userDetail",
        };
      })
      .addCase(getMyInfoAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMyInfoAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getMyInfoAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "myInfo",
        };
      })
      .addCase(postUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = undefined;
        message.success("Thêm Người Dùng thành công");
        window.location.replace("/users");
      })
      .addCase(postUserAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.payload?.status_code === 409) {
          message.error("Trùng email hoặc số điện thoại");
        } else if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error(
            action?.payload?.message || "Thêm Người Dùng thất bại!"
          );
        }
        state.error = {
          payload: action.payload,
          title: "userPost",
        };
      })
      .addCase(putUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putUserAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
        message.success("Cập nhật dữ liệu thành công");
      })
      .addCase(putUserAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.payload?.status_code === 409) {
          message.error("Trùng email hoặc số điện thoại");
        } else if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error(
            action?.payload?.message || "Thêm Người Dùng thất bại!"
          );
        }
        state.error = {
          payload: action.payload,
          title: "userPut",
        };
      })
      .addCase(updateStatusUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStatusUserAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
        message.success("Cập nhật dữ liệu thành công");
      })
      .addCase(updateStatusUserAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Cập nhật dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "userStatus",
          };
        }
      })
      .addCase(resetPasswordUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPasswordUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = undefined;
        message.success("Khôi phục mật khẩu thành công");
      })
      .addCase(resetPasswordUserAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Khôi phục mật khẩu thất bại!");
          state.error = {
            payload: action.payload,
            title: "userResetPassword",
          };
        }
      });
  },
});

export const selectUsers = (state) => state.user.list;
export const selectUserDetail = (state) => state.user.detail;
export const usersStatus = (state) => state.user.status;
export const usersError = (state) => state.user.error;

export const { clearUserDetail, clearUsers, setIdleStatus } = userSlice.actions;

export default userSlice.reducer;
