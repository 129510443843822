import {
  createAsyncThunk,
  // createAsytopicThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { message } from "antd";
import { getNotifications } from "./notiAPI";

const initialState = {
  list: undefined,
  status: "idle",
  error: undefined,
};

export const getNotificationsAsync = createAsyncThunk(
  "notiList",
  async (params, thunkAPI) => {
    try {
      const response = await getNotifications(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const notiSlice = createSlice({
  name: "noti",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearNoti: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNotificationsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNotificationsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "notiList",
        };
      });
  },
});

export const selectNoti = (state) => state.noti.list;
export const notiStatus = (state) => state.noti.status;
export const notiError = (state) => state.noti.error;

export const { clearNoti, setIdleStatus } = notiSlice.actions;

export default notiSlice.reducer;
