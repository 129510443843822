import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getPostsDetail, getPosts, putPostsStatus } from "./postsAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getPostssAsync = createAsyncThunk(
  "postss",
  async (params, thunkAPI) => {
    try {
      const response = await getPosts(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPostsDetailAsync = createAsyncThunk(
  "postsDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getPostsDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusPostsAsync = createAsyncThunk(
  "postsStatus",
  async (params, thunkAPI) => {
    try {
      const response = await putPostsStatus(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearPostsDetail: (state) => {
      state.detail = undefined;
    },
    clearPostss: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPostssAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPostssAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getPostssAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getPostss",
        };
      })
      .addCase(getPostsDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPostsDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getPostsDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "postsDetail",
        };
      })
      .addCase(updateStatusPostsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStatusPostsAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
        message.success("Cập nhật dữ liệu thành công");
      })
      .addCase(updateStatusPostsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Cập nhật dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "postsStatus",
          };
        }
      });
  },
});

export const selectPosts = (state) => state.posts.list;
export const selectPostsDetail = (state) => state.posts.detail;
export const postsStatus = (state) => state.posts.status;
export const postssError = (state) => state.posts.error;

export const { clearPostsDetail, clearPostss, setIdleStatus } =
  postsSlice.actions;

export default postsSlice.reducer;
