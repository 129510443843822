import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getMemberReport = (params, thunkAPI) => {
  return axiosClient.get("v1/account-report", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getMemberReportDetail = (id, thunkAPI) => {
  return axiosClient.get("v1/account-report/" + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const acceptMemberReportStatus = (id, data) => {
  return axiosClient.patch(
    `v1/account-report/${id}/accept-report-account`,
    data,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const rejectMemberReportStatus = (id) => {
  return axiosClient.patch(
    `v1/account-report/${id}/reject-report-account`,
    {},
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const exportMemberReport = (params, thunkAPI) => {
  return axiosClient.get("/v1/export/account-reports", {
    signal: thunkAPI?.signal,
    params,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getMemberReport,
  getMemberReportDetail,
  acceptMemberReportStatus,
  rejectMemberReportStatus,
  exportMemberReport,
};
