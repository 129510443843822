import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getStatistic, getUserChart, getReactionChart } from "./dashboardAPI";

const initialState = {
  statistic: undefined,
  userChart: undefined,
  reactionChart: undefined,
  status: "idle",
  error: undefined,
};

export const getStatisticAsync = createAsyncThunk(
  "statistic",
  async (params, thunkAPI) => {
    try {
      const response = await getStatistic(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUserChartAsync = createAsyncThunk(
  "userChart",
  async (params, thunkAPI) => {
    try {
      const response = await getUserChart(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getReactionChartAsync = createAsyncThunk(
  "reactionChart",
  async (params, thunkAPI) => {
    try {
      const response = await getReactionChart(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getStatisticAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatisticAsync.fulfilled, (state, action) => {
        state.statistic = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getStatisticAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "statistic",
        };
      })
      .addCase(getUserChartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserChartAsync.fulfilled, (state, action) => {
        state.userChart = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getUserChartAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "userChart",
        };
      })
      .addCase(getReactionChartAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReactionChartAsync.fulfilled, (state, action) => {
        state.reactionChart = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getReactionChartAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "reactionChart",
        };
      });
  },
});

export const selectDashboardStatistic = (state) => state.dashboard.statistic;
export const selectDashboardUserChart = (state) => state.dashboard.userChart;
export const selectDashboardReactionChart = (state) =>
  state.dashboard.reactionChart;
export const dashboardStatus = (state) => state.dashboard.status;
export const dashboardError = (state) => state.dashboard.error;

export const { setIdleStatus } = dashboardSlice.actions;

export default dashboardSlice.reducer;
