import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { login } from "./authAPI";

const initialState = {
  token:
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined,
  info: (localStorage?.auth && JSON.parse(localStorage.auth)) || undefined,
  status: "idle",
  errorCode: undefined,
  firstLoginToken: undefined,
};

export const authAsync = createAsyncThunk(
  "login/post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await login(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = undefined;
      state.info = undefined;
      state.errorCode = undefined;
      localStorage.removeItem("auth");
      localStorage.removeItem("token");
      return state;
    },
    updateAuth: (state, action) => {
      state.info = action.payload;
      localStorage.setItem("auth", JSON.stringify(action.payload));
    },
    resetFirstLoginToken: (state) => {
      state.firstLoginToken = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(authAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(authAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.token = action.payload.data.access_token;
        state.info = action.payload.data;
        state.errorCode = undefined;
      })
      .addCase(authAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.payload?.status === 409) {
          state.errorCode = action?.payload?.status;
          return;
        }
        if (action?.payload?.status === 406) {
          if (action?.payload?.data?.result?.is_change_password) {
            state.firstLoginToken = action?.payload?.data?.result?.access_token;
            return;
          }
        }
        message.error("Bạn đã nhập sai tên tài khoản/mật khẩu");
      });
  },
});

export const getInforUser = (state) => state.auth.info;
export const { logout, updateAuth, resetFirstLoginToken } = authSlice.actions;

export default authSlice.reducer;
