import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import "./App.less";
import LoadingComponent from "./components/LoadingComponent";

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./containers/Login"));

function App() {
  return (
    <Suspense fallback={<LoadingComponent inline />}>
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route exact path="/login" component={Login} />
          <Route path="/:locale" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
