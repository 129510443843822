import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getMemberReport, getMemberReportDetail } from "./memberReportAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getMemberReportsAsync = createAsyncThunk(
  "memberReports",
  async (params, thunkAPI) => {
    try {
      const response = await getMemberReport(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getMemberReportDetailAsync = createAsyncThunk(
  "memberReportDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getMemberReportDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const memberReportSlice = createSlice({
  name: "memberReport",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearMemberReportDetail: (state) => {
      state.detail = undefined;
    },
    clearMemberReports: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getMemberReportsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMemberReportsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getMemberReportsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getMemberReports",
        };
      })
      .addCase(getMemberReportDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMemberReportDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getMemberReportDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "memberReportDetail",
        };
      });
  },
});

export const selectMemberReport = (state) => state.memberReport.list;
export const selectMemberReportDetail = (state) => state.memberReport.detail;
export const memberReportStatus = (state) => state.memberReport.status;
export const memberReportsError = (state) => state.memberReport.error;

export const { clearMemberReportDetail, clearMemberReports, setIdleStatus } =
  memberReportSlice.actions;

export default memberReportSlice.reducer;
