import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getMembers = (params, thunkAPI) => {
  return axiosClient.get("v1/user", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getMemberDetail = (id, thunkAPI) => {
  return axiosClient.get("v1/user/" + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putMemberStatus = (data) => {
  return axiosClient.patch(
    "v1/user/" + data?.id + "/change-status-user",
    data?.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const resetPasswordMember = (id) => {
  return axiosClient.patch(
    "v1/user/" + id + "/reset-password",
    {},
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const exportMember = (params, thunkAPI) => {
  return axiosClient.get("/v1/export/users", {
    signal: thunkAPI?.signal,
    params,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getMembers,
  getMemberDetail,
  putMemberStatus,
  resetPasswordMember,
  exportMember,
};
