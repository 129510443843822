import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getPosts = (params, thunkAPI) => {
  return axiosClient.get("v1/posts", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getPostsDetail = (id, thunkAPI) => {
  return axiosClient.get("v1/posts/" + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putPostsStatus = (data) => {
  return axiosClient.patch(
    `v1/posts/${data?.id}/update-status`,
    { ...data?.values },
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const exportPost = (params, thunkAPI) => {
  return axiosClient.get("/v1/export/posts", {
    signal: thunkAPI?.signal,
    params,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getPosts, getPostsDetail, putPostsStatus, exportPost };
