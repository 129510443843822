import axiosClientAuth from "../axiosClientAuth";
import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const login = (data) => {
  return axiosClientAuth.post("v1/auth/sign-in", data);
};

const logout = (data) => {
  return axiosClientAuth.patch(`v1/auth/sign-out`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const refreshToken = (data) => {
  return axiosClientAuth.patch("v1/auth/refresh-token", data);
};

const searchComboBox = (params) => {
  return axiosClient.get("v1/combobox", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const changePasswordUser = (data, token) => {
  return axiosClientAuth.patch(
    "v1/auth/change-password-first-login/change-pw-first-login",
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export { login, logout, searchComboBox, changePasswordUser, refreshToken };
