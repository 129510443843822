import axios from "axios";
import { refreshToken } from "./Auth/authAPI";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      let auth = await instance.getLocalAccessToken();
      let { refresh_token } = auth;
      if (refresh_token) {
        try {
          let { data } = await refreshToken({ refresh_token });
          let { data: _auth } = data;
          // _auth.expired_at = moment().add(+_auth.expires_in, "second").unix();
          let localStorageAuth = JSON.parse(localStorage.auth);
          localStorage.setItem(
            "auth",
            JSON.stringify({ ...localStorageAuth, ..._auth })
          );

          axios.defaults.headers.common["Authorization"] =
            "Bearer " + _auth.access_token;
          originalRequest.headers["Authorization"] =
            "Bearer " + _auth.access_token;
          axios(originalRequest);
          processQueue(null, _auth.access_token);
        } catch (err) {
          if (
            err?.request?.status === 500 ||
            err?.request?.status === 404 ||
            err?.response?.data?.status_message === "INVALID_TOKEN" ||
            err?.response?.data?.status_message === "INVALID_OTP_CODE"
          ) {
            localStorage.removeItem("auth");
            localStorage.removeItem("token");
            window.location.replace("/");
            return;
          }
          processQueue(err, null);
        } finally {
          isRefreshing = false;
        }
      } else {
        Promise.reject(error);
        isRefreshing = false;
        localStorage.removeItem("auth");
        localStorage.removeItem("token");
        window.location.replace("/");
      }
    }
    // if (error?.response?.status === 401) {
    //   localStorage.removeItem("auth");
    //   localStorage.removeItem("token");
    //   window.location.replace("/");
    // }
    return Promise.reject(error);
  }
);

instance.getLocalAccessToken = async () => {
  const auth = localStorage.auth ?? "{}";
  return JSON.parse(auth);
};

export default instance;
