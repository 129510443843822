import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getUsers = (params, thunkAPI) => {
  return axiosClient.get("v1/accounts", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getUserDetail = (id, thunkAPI) => {
  return axiosClient.get("v1/accounts/" + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getMyInfo = (thunkAPI) => {
  return axiosClient.get("v1/accounts/information-current-account", {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postUser = (data) => {
  return axiosClient.post("v1/accounts/create-account", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putUserStatus = (data) => {
  return axiosClient.patch(
    "v1/accounts/" + data?.id + "/change-status-account",
    data?.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const putUser = (data) => {
  return axiosClient.patch(
    "v1/accounts/update-account/" + data?.id,
    data?.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const resetPasswordUser = (id) => {
  return axiosClient.patch(
    "v1/accounts/" + id + "/reset-password",
    {},
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const exportUser = (params, thunkAPI) => {
  return axiosClient.get("/v1/export/accounts", {
    signal: thunkAPI?.signal,
    params,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getUsers,
  getUserDetail,
  postUser,
  putUserStatus,
  resetPasswordUser,
  getMyInfo,
  putUser,
  exportUser,
};
