import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getMemberDetail,
  getMembers,
  putMemberStatus,
  resetPasswordMember,
} from "./memberAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getMembersAsync = createAsyncThunk(
  "members",
  async (params, thunkAPI) => {
    try {
      const response = await getMembers(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getMemberDetailAsync = createAsyncThunk(
  "memberDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getMemberDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusMemberAsync = createAsyncThunk(
  "memberStatus",
  async (params, thunkAPI) => {
    try {
      const response = await putMemberStatus(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetPasswordMemberAsync = createAsyncThunk(
  "memberResetPassword",
  async (params, thunkAPI) => {
    try {
      const response = await resetPasswordMember(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearMemberDetail: (state) => {
      state.detail = undefined;
    },
    clearMembers: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getMembersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMembersAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getMembersAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getMembers",
        };
      })
      .addCase(getMemberDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMemberDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getMemberDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "memberDetail",
        };
      })
      .addCase(updateStatusMemberAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStatusMemberAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
        message.success("Cập nhật dữ liệu thành công");
      })
      .addCase(updateStatusMemberAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Cập nhật dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "memberStatus",
          };
        }
      })
      .addCase(resetPasswordMemberAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPasswordMemberAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = undefined;
        message.success("Khôi phục mật khẩu thành công");
      })
      .addCase(resetPasswordMemberAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Khôi phục mật khẩu thất bại!");
          state.error = {
            payload: action.payload,
            title: "memberResetPassword",
          };
        }
      });
  },
});

export const selectMembers = (state) => state.member.list;
export const selectMemberDetail = (state) => state.member.detail;
export const membersStatus = (state) => state.member.status;
export const membersError = (state) => state.member.error;

export const { clearMemberDetail, clearMembers, setIdleStatus } =
  memberSlice.actions;

export default memberSlice.reducer;
