import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getKeyword = (params, thunkAPI) => {
  return axiosClient.get("v1/sensitive-word", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const updateKeyword = (data) => {
  return axiosClient.patch(
    `v1/sensitive-word/${data?.id}`,
    { ...data?.data },
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const addKeyword = (data) => {
  return axiosClient.post(`v1/sensitive-word`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const importKeyword = (data) => {
  return axiosClient.post(`v1/sensitive-word/list-import`, data, {
    headers: {
      "Content-Type": "multipart/form-data;",
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportKeyword = (params, thunkAPI) => {
  return axiosClient.get("/v1/export/sensitive-words", {
    signal: thunkAPI?.signal,
    params,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportKeywordTemplate = (params, thunkAPI) => {
  return axiosClient.get("/v1/sensitive-word/template-import", {
    signal: thunkAPI?.signal,
    params,
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getKeyword,
  addKeyword,
  updateKeyword,
  importKeyword,
  exportKeyword,
  exportKeywordTemplate,
};
