import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getEventDetail, getEvent, putEventStatus } from "./eventAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getEventsAsync = createAsyncThunk(
  "events",
  async (params, thunkAPI) => {
    try {
      const response = await getEvent(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEventDetailAsync = createAsyncThunk(
  "eventDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getEventDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateStatusEventAsync = createAsyncThunk(
  "eventStatus",
  async (params, thunkAPI) => {
    try {
      const response = await putEventStatus(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearEventDetail: (state) => {
      state.detail = undefined;
    },
    clearEvents: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getEventsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEventsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getEventsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getEvents",
        };
      })
      .addCase(getEventDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEventDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getEventDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "eventDetail",
        };
      })
      .addCase(updateStatusEventAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStatusEventAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
        message.success("Cập nhật dữ liệu thành công");
      })
      .addCase(updateStatusEventAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Cập nhật dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "eventStatus",
          };
        }
      });
  },
});

export const selectEvent = (state) => state.event.list;
export const selectEventDetail = (state) => state.event.detail;
export const eventStatus = (state) => state.event.status;
export const eventsError = (state) => state.event.error;

export const { clearEventDetail, clearEvents, setIdleStatus } =
  eventSlice.actions;

export default eventSlice.reducer;
