import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.access_token) ||
    undefined
  );
};

const getStatistic = (params, thunkAPI) => {
  return axiosClient.get("v1/dash-board/total-count", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getUserChart = (params, thunkAPI) => {
  return axiosClient.get("v1/dash-board/total-count-user", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getReactionChart = (params, thunkAPI) => {
  return axiosClient.get("v1/dash-board/total-count-reaction", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getStatistic, getUserChart, getReactionChart };
