import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getPostsReportDetail, getPostsReport } from "./postsReportAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getPostsReportsAsync = createAsyncThunk(
  "postsReports",
  async (params, thunkAPI) => {
    try {
      const response = await getPostsReport(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPostsReportDetailAsync = createAsyncThunk(
  "postsReportDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getPostsReportDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postsReportSlice = createSlice({
  name: "postsReport",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearPostsReportDetail: (state) => {
      state.detail = undefined;
    },
    clearPostsReports: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPostsReportsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPostsReportsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getPostsReportsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getPostsReports",
        };
      })
      .addCase(getPostsReportDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPostsReportDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload?.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getPostsReportDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "postsReportDetail",
        };
      });
  },
});

export const selectPostsReport = (state) => state.postsReport.list;
export const selectPostsReportDetail = (state) => state.postsReport.detail;
export const postsReportStatus = (state) => state.postsReport.status;
export const postsReportsError = (state) => state.postsReport.error;

export const { clearPostsReportDetail, clearPostsReports, setIdleStatus } =
  postsReportSlice.actions;

export default postsReportSlice.reducer;
